var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("New Commerical Modification Request")]),_c('br'),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.requestModification.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.commercialMod.unit_id.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUserUnits},model:{value:(_vm.commercialMod.unit_id),callback:function ($$v) {_vm.$set(_vm.commercialMod, "unit_id", $$v)},expression:"commercialMod.unit_id"}}),(_vm.submitted && _vm.$v.commercialMod.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Date For Visit*")]),_c('b-form-datepicker',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.commercialMod.date.$error,
                        },attrs:{"min":_vm.min},model:{value:(_vm.commercialMod.date),callback:function ($$v) {_vm.$set(_vm.commercialMod, "date", $$v)},expression:"commercialMod.date"}}),(_vm.submitted && _vm.$v.commercialMod.date.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.date.required)?_c('span',[_vm._v("Date is required ")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Contractor Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.commercialMod.contractor_name),expression:"commercialMod.contractor_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.commercialMod.contractor_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.commercialMod.contractor_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.commercialMod, "contractor_name", $event.target.value)}}}),(
                            _vm.submitted &&
                            _vm.$v.commercialMod.contractor_name.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                                !_vm.$v.commercialMod.contractor_name.required
                            )?_c('span',[_vm._v("Contractor name required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Contact Person*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.commercialMod.contact_person_name),expression:"commercialMod.contact_person_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.commercialMod.contact_person_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.commercialMod.contact_person_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.commercialMod, "contact_person_name", $event.target.value)}}}),(
                            _vm.submitted &&
                            _vm.$v.commercialMod.contact_person_name.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                                !_vm.$v.commercialMod.contact_person_name
                                    .required
                            )?_c('span',[_vm._v("Contact person name required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Office Telephone Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.commercialMod.contact_person_office_no),expression:"commercialMod.contact_person_office_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.commercialMod.contact_person_office_no
                                    .$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.commercialMod.contact_person_office_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.commercialMod, "contact_person_office_no", $event.target.value)}}}),(
                            _vm.submitted &&
                            _vm.$v.commercialMod.contact_person_office_no.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                                !_vm.$v.commercialMod.contact_person_office_no
                                    .required
                            )?_c('span',[_vm._v("Telephone number required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Mobile Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.commercialMod.contact_person_mobile_no),expression:"commercialMod.contact_person_mobile_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.commercialMod.contact_person_mobile_no
                                    .$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.commercialMod.contact_person_mobile_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.commercialMod, "contact_person_mobile_no", $event.target.value)}}}),(
                            _vm.submitted &&
                            _vm.$v.commercialMod.contact_person_mobile_no.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                                !_vm.$v.commercialMod.contact_person_mobile_no
                                    .required
                            )?_c('span',[_vm._v("Mobile number required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Fax")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.commercialMod.contact_person_fax_no),expression:"commercialMod.contact_person_fax_no"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.commercialMod.contact_person_fax_no
                                    .$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.commercialMod.contact_person_fax_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.commercialMod, "contact_person_fax_no", $event.target.value)}}}),(
                            _vm.submitted &&
                            _vm.$v.commercialMod.contact_person_fax_no.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                                !_vm.$v.commercialMod.contact_person_fax_no
                                    .numeric
                            )?_c('span',[_vm._v("Only numeric values allowed")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Type Of Alteration*")]),_c('b-form-radio-group',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.commercialMod.type.$error,
                    },attrs:{"options":_vm.modificationOptions,"value-field":"item","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.commercialMod.type),callback:function ($$v) {_vm.$set(_vm.commercialMod, "type", $$v)},expression:"commercialMod.type"}}),(_vm.submitted && _vm.$v.commercialMod.type.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.type.required)?_c('span',[_vm._v("Commerical modification type required")]):_vm._e()]):_vm._e()],1)]),(_vm.commercialMod.type === 'Other')?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Other")]),_c('b-form-textarea',{class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.commercialMod.type_other.$error,
                    },attrs:{"id":"textarea-small","size":"sm"},model:{value:(_vm.commercialMod.type_other),callback:function ($$v) {_vm.$set(_vm.commercialMod, "type_other", $$v)},expression:"commercialMod.type_other"}}),(_vm.submitted && _vm.$v.commercialMod.type_other.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.type_other.required)?_c('span',[_vm._v("Value is required")]):_vm._e()]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Description of your alteration*")]),_c('div',{staticClass:"form-group"},[_c('b-form-textarea',{class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.commercialMod.details.$error,
                        },attrs:{"id":"textarea-small","size":"sm"},model:{value:(_vm.commercialMod.details),callback:function ($$v) {_vm.$set(_vm.commercialMod, "details", $$v)},expression:"commercialMod.details"}}),(_vm.submitted && _vm.$v.commercialMod.details.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.details.required)?_c('span',[_vm._v("Description is required ")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Trade license copy*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted &&
                            _vm.$v.commercialMod.trade_licence.$error,
                    },attrs:{"placeholder":"Choose File","id":"tradeLicense","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":function($event){return _vm.handleReleaseLetter($event)}}}),(
                        _vm.submitted && _vm.$v.commercialMod.trade_licence.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.trade_licence.required)?_c('span',[_vm._v("Trade license copy is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Letter of undertaking*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted &&
                            _vm.$v.commercialMod.letter_of_agreement.$error,
                    },attrs:{"placeholder":"Choose File","id":"undertaking","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":function($event){return _vm.handleReleaseLetter($event)}}}),(
                        _vm.submitted &&
                        _vm.$v.commercialMod.letter_of_agreement.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(
                            !_vm.$v.commercialMod.letter_of_agreement.required
                        )?_c('span',[_vm._v("Letter of undertaking required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Drawings*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.commercialMod.drawings.$error,
                    },attrs:{"placeholder":"Choose File","id":"drawing","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":function($event){return _vm.handleReleaseLetter($event)}}}),(_vm.submitted && _vm.$v.commercialMod.drawings.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.drawings.required)?_c('span',[_vm._v("Drawing required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v(" Scope of work*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted &&
                            _vm.$v.commercialMod.scope_of_work.$error,
                    },attrs:{"placeholder":"Choose File","id":"scope","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":function($event){return _vm.handleReleaseLetter($event)}}}),(
                        _vm.submitted && _vm.$v.commercialMod.scope_of_work.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.commercialMod.scope_of_work.required)?_c('span',[_vm._v("Scope of work required")]):_vm._e()]):_vm._e()],1)]),_c('h5',[_vm._v("Terms & Conditions")]),_c('ol',[_c('li',[_vm._v(" Addition / Alterations / Improvements require the written consent of falconcity of wonders. Additionally you may obtain written consent from Dubai municipality. Please complete this form with full details of your plans and include all engineering drawings and supporting documentation for evaluation. ")]),_c('li',[_vm._v(" If the request is granted, you will receive written consent from Falconcity. You will then need to obtain all necessary statutory approvals from Dubai municipality and other relevant departments before starting works. Architectural charges require a properly licensed and insured contractor. A copy of the Insurance Certificate must be submitted to Falconcity prior to construction."),_c('br'),_c('strong',[_vm._v("\"The area targeted by alteration in the said unit will be out of DLP Defects liability period\"")])])]),_c('p',[_vm._v(" For any more information please don’t hesitate to contact us on Tel no: +971 4 327 1737 OR Email: customerservice@falconcity.com ")]),_c('br'),_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":"true","unchecked-value":"not_accepted"},on:{"change":function($event){return _vm.agree()}}},[_vm._v(" I understand and agree to the Terms & Conditions mentioned above and all the above filled information is true ")]),_c('div',{staticClass:"mt-3 mb-3"},[_c('button',{staticClass:"btn btn-primary btn-lg",attrs:{"type":"submit","disabled":!_vm.agreed}},[_vm._v(" Submit ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }