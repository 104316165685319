<template>
    <Layout>
        <h4>New Commerical Modification Request</h4>
        <br />
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestModification"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="commercialMod.unit_id"
                            :options="mappedUserUnits"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.commercialMod.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.commercialMod.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.commercialMod.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"> Date For Visit*</label>
                        <b-form-datepicker
                            :min="min"
                            v-model="commercialMod.date"
                            :class="{
                                'is-invalid':
                                    submitted && $v.commercialMod.date.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="submitted && $v.commercialMod.date.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.commercialMod.date.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contractor Name*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-model="commercialMod.contractor_name"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.commercialMod.contractor_name.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.commercialMod.contractor_name.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.commercialMod.contractor_name.required
                                "
                                >Contractor name required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contact Person*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="commercialMod.contact_person_name"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.commercialMod.contact_person_name.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.commercialMod.contact_person_name.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.commercialMod.contact_person_name
                                        .required
                                "
                                >Contact person name required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Office Telephone Number*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="commercialMod.contact_person_office_no"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.commercialMod.contact_person_office_no
                                        .$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.commercialMod.contact_person_office_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.commercialMod.contact_person_office_no
                                        .required
                                "
                                >Telephone number required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Mobile Number*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="commercialMod.contact_person_mobile_no"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.commercialMod.contact_person_mobile_no
                                        .$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.commercialMod.contact_person_mobile_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.commercialMod.contact_person_mobile_no
                                        .required
                                "
                                >Mobile number required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Fax</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="commercialMod.contact_person_fax_no"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.commercialMod.contact_person_fax_no
                                        .$error,
                            }"
                            id="username"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.commercialMod.contact_person_fax_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.commercialMod.contact_person_fax_no
                                        .numeric
                                "
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">Type Of Alteration*</label>

                    <b-form-radio-group
                        :options="modificationOptions"
                        v-model="commercialMod.type"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                        :class="{
                            'is-invalid':
                                submitted && $v.commercialMod.type.$error,
                        }"
                    ></b-form-radio-group>
                    <div
                        v-if="submitted && $v.commercialMod.type.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.commercialMod.type.required"
                            >Commerical modification type required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-2" v-if="commercialMod.type === 'Other'">
                <div class="col-lg-12">
                    <label for="ownerfname"> Other</label>
                    <b-form-textarea
                        id="textarea-small"
                        v-model="commercialMod.type_other"
                        size="sm"
                        :class="{
                            'is-invalid':
                                submitted && $v.commercialMod.type_other.$error,
                        }"
                    ></b-form-textarea>
                    <div
                        v-if="submitted && $v.commercialMod.type_other.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.commercialMod.type_other.required"
                            >Value is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-12">
                    <label for="ownerfname">
                        Description of your alteration*</label
                    >
                    <div class="form-group">
                        <b-form-textarea
                            id="textarea-small"
                            v-model="commercialMod.details"
                            size="sm"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.commercialMod.details.$error,
                            }"
                        ></b-form-textarea>
                        <div
                            v-if="submitted && $v.commercialMod.details.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.commercialMod.details.required"
                                >Description is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label for="ownerfname">Trade license copy*</label>
                    <b-form-file
                        placeholder="Choose File"
                        id="tradeLicense"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.commercialMod.trade_licence.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted && $v.commercialMod.trade_licence.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.commercialMod.trade_licence.required"
                            >Trade license copy is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname"> Letter of undertaking*</label>
                    <b-form-file
                        placeholder="Choose File"
                        id="undertaking"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.commercialMod.letter_of_agreement.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted &&
                            $v.commercialMod.letter_of_agreement.$error
                        "
                        class="invalid-feedback"
                    >
                        <span
                            v-if="
                                !$v.commercialMod.letter_of_agreement.required
                            "
                            >Letter of undertaking required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label for="ownerfname">Drawings*</label>
                    <b-form-file
                        placeholder="Choose File"
                        id="drawing"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        :class="{
                            'is-invalid':
                                submitted && $v.commercialMod.drawings.$error,
                        }"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.commercialMod.drawings.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.commercialMod.drawings.required"
                            >Drawing required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname"> Scope of work*</label>
                    <b-form-file
                        placeholder="Choose File"
                        id="scope"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.commercialMod.scope_of_work.$error,
                        }"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted && $v.commercialMod.scope_of_work.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.commercialMod.scope_of_work.required"
                            >Scope of work required</span
                        >
                    </div>
                </div>
            </div>
            <h5>Terms & Conditions</h5>

            <ol>
                <li>
                    Addition / Alterations / Improvements require the written
                    consent of falconcity of wonders. Additionally you may
                    obtain written consent from Dubai municipality. Please
                    complete this form with full details of your plans and
                    include all engineering drawings and supporting
                    documentation for evaluation.
                </li>
                <li>
                    If the request is granted, you will receive written consent
                    from Falconcity. You will then need to obtain all necessary
                    statutory approvals from Dubai municipality and other
                    relevant departments before starting works. Architectural
                    charges require a properly licensed and insured contractor.
                    A copy of the Insurance Certificate must be submitted to
                    Falconcity prior to construction.<br />
                    <strong
                        >"The area targeted by alteration in the said unit will
                        be out of DLP Defects liability period"</strong
                    >
                </li>
            </ol>

            <p>
                For any more information please don’t hesitate to contact us on
                Tel no: +971 4 327 1737 OR Email: customerservice@falconcity.com
            </p>

            <br />
            <b-form-checkbox
                id="checkbox-1"
                @change="agree()"
                name="checkbox-1"
                value="true"
                unchecked-value="not_accepted"
            >
                I understand and agree to the Terms & Conditions mentioned above
                and all the above filled information is true
            </b-form-checkbox>
            <div class="mt-3 mb-3">
                <button
                    type="submit"
                    :disabled="!agreed"
                    class="btn btn-primary btn-lg"
                >
                    Submit
                </button>
            </div>
        </form>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
// eslint-disable-next-line no-unused-vars
import { BASE_URL, COMMERICIAL_MODES_TYPES } from "../../common";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";
import alertMixin from "../../../mixins/alertMixin";

export default {
    mixins: [alertMixin],
    components: {
        Layout,
    },
    validations: {
        commercialMod: {
            contact_person_fax_no: { numeric },
            contact_person_mobile_no: { required, numeric },
            contact_person_name: { required },
            contact_person_office_no: { required, numeric },
            contractor_name: { required },
            date: { required },
            details: { required },
            drawings: { required },
            letter_of_agreement: { required },
            scope_of_work: { required },
            trade_licence: { required },
            type: { required },
            unit_id: { required },
            type_other: {
                required: requiredIf(function () {
                    return this.commercialMod.type === "Other";
                }),
            },
        },
    },
    data() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const minDate = new Date(today);
        return {
            role: "",
            submitted: false,
            agreed: false,
            min: minDate,
            payment: "",
            selected: "",
            commercialMod: {
                contact_person_fax_no: "",
                contact_person_mobile_no: "",
                contact_person_name: "",
                contact_person_office_no: "",
                contractor_name: "",
                date: "",
                details: "",
                drawings: "",
                drawings_filename: "",
                letter_of_agreement: "",
                letter_of_agreement_filename: "",
                scope_of_work: "",
                scope_of_work_filename: "",
                trade_licence: "",
                trade_licence_filename: "",
                type: "",
                unit_id: "",
                type_other: "",
            },
        };
    },
    methods: {
        agree() {
            this.agreed = !this.agreed;
        },
        handleReleaseLetter(e) {
            let id = e.srcElement.id;
            this.constructBase64(e, id);
        },
        constructBase64(e, id) {
            let reader = new FileReader();

            reader.onload = (f) => {
                switch (id) {
                    case "tradeLicense":
                        this.commercialMod.trade_licence_filename =
                            e.target.files[0].name;
                        this.commercialMod.trade_licence =
                            f.target.result.split(",")[1];
                        break;

                    case "undertaking":
                        this.commercialMod.letter_of_agreement_filename =
                            e.target.files[0].name;
                        this.commercialMod.letter_of_agreement =
                            f.target.result.split(",")[1];
                        break;

                    case "scope":
                        this.commercialMod.scope_of_work_filename =
                            e.target.files[0].name;
                        this.commercialMod.scope_of_work =
                            f.target.result.split(",")[1];
                        break;
                    case "drawing":
                        (this.commercialMod.drawings_filename =
                            e.target.files[0].name),
                            (this.commercialMod.drawings =
                                f.target.result.split(",")[1]);
                        break;
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        },
        requestModification() {
            console.log("submitted");
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.commercialMod);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}commercialmodifications?token=${localStorage.cs_user_token}`,
                            this.commercialMod
                        )
                        .then((res) => {
                            this.resetFields();
                            this.agreed = false;
                            console.log(res.data.response);
                            this.successMsg(
                                res.data.response.ref_no,
                                "Success! Your request has been received by the Customer Service Team."
                            );
                            loader.hide();
                            this.$router.push("/");
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
    },

    computed: {
        modificationOptions() {
            return COMMERICIAL_MODES_TYPES.map((ele) => {
                return {
                    item: ele,
                    name: ele,
                };
            });
        },
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
};
</script>
